import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Main Subscription Agreement" />
    <TermsSection
      title="Main Subscription Agreement"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
  <>
    <h2>Main Subscription Agreement</h2>
    <p>
      THIS MAIN SUBSCRIPTION AGREEMENT GOVERNS CUSTOMER’S USE OF THE SPRIVE SERVICES.  BY USING THE SPRIVE SERVICES, CUSTOMER AGREES TO COMPLY WITH THESE TERMS OF USE.
    </p>

    <p>Last Updated: September 19, 2023</p>

    <p>
      These Terms will apply to your use of Sprive and the provision of our services to
      you. Please read these Terms carefully and make sure that you understand them,
      before using this service via our website. Please note that before you use Sprive you
      will be asked to agree to these Terms. You must not use this website if you do not
      agree to the terms and conditions, are under 18 or are a non-UK resident. If you
      refuse to accept these Terms, you will not be able to use this service via our website.
    </p>

    <h4>1. Defined Terms:</h4>
    <p><strong>“Business Day”</strong> means a day other than a Saturday, Sunday or public holiday in England;</p>

    <p><strong>“Controller, Processor, Data Subject, Personal Data, Personal Data Breach, processing and appropriate technical and organisational measures”</strong> shall mean as defined in the Data Protection Legislation;</p>

    <p><strong>“Customer”</strong> means you;</p>

    <p><strong>“Customer Support”</strong> means support provided by Sprive to You in connection with the Services;</p>

    <p><strong>“Data Protection Legislation”</strong> means the UK Data Protection Legislation and any other European Union legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of Personal Data (including, without limitation, the privacy of electronic communications); and the guidance and codes of practice issued by the relevant data protection or supervisory authority and applicable to a party.</p>

    <p><strong>“Effective Date”</strong> means the date to when the Customer has signed the Order Form;</p>

    <p><strong>“End User”</strong> means a client of Yours who has an active direct debit to their Sprive e-money account in place;</p>

    <p><strong>“Initial Term”</strong> means a period of one (1) year from the Effective Date;</p>

    <p><strong>“Leads”</strong> shall mean information provided by Supplier concerning any potential client, including its name, its address, account status and otherwise that could be used to identify the client and is private and not generally available to the public, however stored or collected and has opted to have such personal data disclosed;</p>

    <p><strong>“Monthly Active Users or MAU”</strong> shall mean the number of End Users who have an active direct debit in place to move money into their Sprive e-money account;</p>

    <p><strong>“Order Form”</strong> shall mean the ordering document for Customer’s purchases of services from Sprive that are executed hereunder from time to time, including any schedules or addenda thereto;</p>

    <p><strong>“Sprive Services”</strong> shall mean the specific proprietary and general available software-as a service product of Sprive, specified in Customer’s Order Form;</p>

    <p><strong>“Subscription Term”</strong> shall mean the subscription period set forth on an applicable Order Form;</p>

    <p><strong>“Supplier”</strong> means Sprive Limited a company incorporated and registered in England and Wales with company number 12102682 whose registered office is at Cannon Place, 78 Cannon Street, London, United Kingdom, EC4N 6AF;</p>

    <p><strong>“UK Data Protection Legislation”</strong> shall mean all applicable data protection and privacy legislation in force from time to time in the UK including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended;</p>

    <h4>2. Provision of Services</h4>
    <p>2.1	Sprive shall make the Sprive Services available to Customer pursuant to this Agreement and all Order Forms during a Subscription Term.  Customer’s purchase of the Sprive Services is not contingent upon the delivery of any future functionality or features, or dependent on any oral or written public comments made by Sprive regarding future functionality or features.</p>

    <p>2.2	Sprive shall  provide commercially reasonable efforts to make the Sprive Services available 24 hours a day, seven days a week for the end user except for any unavailability caused by circumstances beyond Sprive’s reasonable control, including without limitation, any event of force majeure, acts of nature, acts of government, fire, civil unrest, acts of terror, earthquakes, pandemic, epidemic, strikes or other labour problems (other than those involving Sprive employees), internet service provider, Third-Party Providers, hosting providers, failures or delays involving hardware, software or power systems not within Sprive’s possession or reasonable control, and denial of service attacks; </p>

    <p>2.3	Sprive shall provide the Sprive Services in compliance with applicable laws and regulations related to data protection, anti-bribery and corruption; and in maintaining and enforcing its own internal policies to ensure compliance in all areas except to the extent any breach of the Agreement by Customer causes Sprive to be in violation of applicable law;</p>

    <h4>3. Payment Terms</h4>
    <p>3.1 Customer shall pay all fees specified in all signed Order Forms hereunder.  Except as otherwise specified herein or in an Order Form, </p>
    <p>3.2 Supplier will issue an invoice the Set Up Fee (“Set Up Fee”) as stated within the Order Form.  The Set Up Fee and the first License Fee are payable within 7 calendar days of the invoice date via bank transfer into the bank account designated on the Supplier’s invoice.  If the Supplier has not received payment by the due date the Supplier will pause obligations on the Agreement until payment of the Set Up Fee and first License Fee.  The Supplier shall be under no obligation to provide any or all Services while the invoices concerned remain unpaid.</p>

    <p>3.3 You shall pay to Supplier a License Fee as per the amount set forth in the order form (“License Fee”) including any minimum commitments therein. The License Fee is due one business day before the start of each Invoicing Period. The first Invoicing Period commences on the Effective Date and will be invoiced monthly for the duration of Term. If the Supplier has not received payment of the License Fee within 5 Business Days of a payment becoming due, and without prejudice to any other rights and remedies of the Supplier, the Supplier may suspend access to all Services and the Supplier shall be under no obligation to provide any or all of the Services while the payment(s) concerned remain unpaid thereafter.</p>

    <p>3.4 In addition, you will be charged a Customer usage fee as detailed in the Order Form which will vary depending on the number of the Customers’ clients using its services.</p>

    <p>3.5 You are responsible for all taxes arising out of this agreement.</p>

    <p>3.6 Supplier reserves the right to collect any fees due via direct debit facility.  Unless expressly agreed otherwise in writing, the Supplier will always collect payment via its direct debit facility.  The Supplier shall send You a direct debit mandate and You hereby undertake to complete and return the direct debit mandate to the Supplier.  Once the direct debit mandate has been processed the fees will automatically collected and paid to the Supplier on each Payment Date until the termination of this agreement.</p>

    <p>3.7 Where the supplier elects to not collect payments via direct debit payments, all payments made by You to Supplier shall be made by electronic transfer.  For the avoidance of doubt, payment by cheque or cash shall not be accepted. </p>

    <h4>4. Customer and Supplier Obligations</h4>
    <p>4.1 The Customer and Supplier shall;</p>
    <p>4.1.1 Cooperate with each other in all matters relating to the Services:</p>
    <p>4.1.2 Provide, in a timely manner, any information as each Party may reasonably request from each other in order to fulfil their obligations to supply a good service. The information must be accurate and up to date.</p>
    <p>4.1.3 Obtain and maintain all necessary licenses, consents and comply with all relevant legislation in relation to Data Protection and Financial Services before the date of the start of Services.</p>
    <p>4.2 Customer shall at all times act in good faith towards Supplier.</p>
    <p>4.3 Within 30 business days of the new mortgage being completed in relation to a Client, Customer will provide the Supplier with a copy of the mortgage offer documents (subject to Client’s consent), which shall include details of ERC penalties associated with the Client’s new mortgage.</p>
    <p>4.4 Customer agrees to have sufficient resourcing and personnel to ensure Clients can speak to an expert in relation to any Product within 2 business days.</p>
    <p>4.5 In the event that either Party is exposed to information or knowledge which could adversely affect the business relationship between the two Parties, this information should be shared respectively with the Customer or Supplier as soon as is reasonably possible in an effort to mitigate damage.</p>
    <p>4.6 Sprive shall provide technical, customer and onboarding support for the Sprive Services in accordance with the Order Form.</p>
    <p>4.7 Supplier shall ensure all mortgage, protection and life insurance appointments generated as a result of Sprive Services will be directed only to the customer.</p>

    <h4>5. Defective Services</h4>
    <p>5.1 Supplier shall promptly notify the Customer of:</p>
    <p>5.1.1 Any delays/problems or circumstances from time to time which may prevent Supplier from providing the Services set out in this Agreement.</p>
    <p>5.1.2 Any complaint (whether written or verbal) or other matter which comes to its attention which it reasonably believes may give rise to any loss or claim against the Customer or which may result in adverse publicity for the Customer’s organisation.</p>
    <p>5.2 Supplier Shall:</p>
    <p>5.2.1 Not make any offer, promise, compromise, settlement or communication with any third party in respect of the matter, or admit liability in respect of or settle the matter without the prior written consent of Customer.</p>
    <p>5.2.2 Provide to the Customer and its professional advisers reasonable access to any relevant documents and records within the Supplier’s possession or control for the purposes of investigating the matter and enabling Customer to take such action required in defence of the loss or claim as is referred to in clause 5.1.2.</p>
    <p>5.3 Customer shall notify Supplier of:</p>
    <p>5.3.1 Any defects in Supplier’s performance of the supplied Services as soon as reasonably practical after any such defect comes to its attention. Supplier will then endeavour to remedy any such defect in performance as soon as is reasonably practicable.</p>

    <h4>Change Control</h4>
<p>This Agreement constitutes the entire agreement and understanding of the Parties and may only be varied or amended by agreement in writing of a duly authorised representative of Customer and Supplier. Where Customer or Supplier sees a need to change the Services Customer may at any time request, and Supplier may at any time recommend such change. Neither Party will unreasonably withhold its agreement to any change.</p>

<h4>7. Termination of Agreement</h4>
<p>7.1 This Agreement shall commence on the Effective Date and shall continue thereafter until the expiration of the Initial Term unless terminated as permitted herein. At the end of the Initial Term (and each Renewal Term) this Agreement shall automatically renew for a further Renewal Term unless either party gives the other written notice of non-renewal at least 180 days before the end of the Initial Term or the then current Renewal Term.</p>

<p>7.2 This Agreement may be terminated in any of the following circumstances:</p>
<p>7.2.1 by either party immediately upon notice in writing to the other in the event that that other party is in breach of its obligations under this Agreement and, in the case of a breach which is not persistent and can be remedied, that party has not remedied such breach within 30 days of receipt of a written notice specifying the breach and requiring its remedy; or</p>
<p>7.2.2 by either party immediately upon notice in writing in the event that the other party goes into compulsory or voluntary liquidation (except for the purposes of solvent reconstruction or amalgamation) or a receiver, administrative receiver or administrator is appointed in respect of the whole or any part of its assets or in the event that it makes an assignment for the benefit of or composition with its creditors generally or engages in or suffers any similar procedure in any jurisdiction.</p>
<p>7.3 Upon termination of this agreement all mortgage, protection and life insurance appointments generated as a result of Sprive Services will continue to be directed to the customer for a period of one year.</p>

<h4>8. Data Protection and GDPR</h4>
<p>8.1 Both parties will comply with all applicable requirements of the Data Protection Legislation. This clause 9 is in addition to, and does not relieve, remove or replace, a party’s obligations or rights under the Data Protection Legislation. In this clause 9 Applicable Laws means (for so long as and to the extent that they apply to the Provider) the law of the European Union, the law of any member state of the European Union and/or Domestic UK Law; and Domestic UK Law means the UK Data Protection Legislation and any other law that applies in the UK.</p>

<p>8.2 The parties acknowledge that for the purposes of the Data Protection Legislation, the Supplier is the Controller and the Customer is the Processor. Appendix 1 sets out the scope, nature and purpose of processing by the Customer, the duration of the processing and the types of Personal Data and categories of Data Subject.</p>

<p>Without prejudice to the generality of clause 9.1 the Supplier will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the Personal Data to the Customer for the duration and purposes of this agreement.</p>

<p>8.3 Without prejudice to the generality of clause 9.1, the Customer shall, in relation to any Personal Data processed in connection with the performance by the Customer of its obligations under this Agreement:</p>
<p>8.3.1 process that Personal Data only on the documented written instructions of the Supplier which are set out in Appendix 2 unless the Customer is required by Applicable Laws to otherwise process that Personal Data. Where the Customer is relying on Applicable Laws as the basis for processing Personal Data, the Customer shall promptly notify the Supplier of this before performing the processing required by the Applicable Laws unless those Applicable Laws prohibit the Customer from so notifying the Supplier;</p>
<p>8.3.2 ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the Supplier, to protect against unauthorised or unlawful processing of Personal Data and against accidental loss or destruction of, or damage to, Personal Data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measures (those measures may include, where appropriate, pseudonymising and encrypting Personal Data, ensuring confidentiality, integrity, availability and resilience of its systems and services, ensuring that availability of and access to Personal Data can be restored in a timely manner after an incident, and regularly assessing and evaluating the effectiveness of the technical and organisational measures adopted by it);</p>
<p>8.3.3 ensure that all personnel who have access to and/or process Personal Data are obliged to keep the Personal Data confidential; and</p>
<p>8.3.4 not transfer any Personal Data outside of the European Economic Area unless the prior written consent of the Supplier has been obtained and the following conditions are fulfilled:</p>
<p>  (i) the Supplier or the Customer has provided appropriate safeguards in relation to the transfer;</p>
<p>  (ii) the data subject has enforceable rights and effective legal remedies;</p>
<p>  (iii) the Customer complies with its obligations under the Data Protection Legislation by providing an adequate level of protection to any Personal Data that is transferred; and</p>
<p>  (iv) the Customer complies with reasonable instructions notified to it in advance by the Supplier with respect to the processing of the Personal Data;</p>
<p>8.3.5 assist the Supplier, at the Supplier’s cost, in responding to any request from a Data Subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</p>
<p>8.3.6 notify the Supplier without undue delay on becoming aware of a Personal Data Breach;</p>
<p>8.3.7 at the written direction of the Supplier, delete or return Personal Data and copies thereof to the Supplier on termination of the agreement unless required by Applicable Law to store the Personal Data; and</p>
<p>8.3.8 maintain complete and accurate records and information to demonstrate its compliance with this clause 9 and allow for audits by the Supplier or the Supplier’s designated auditor and immediately inform the Supplier if, in the opinion of the Customer, an instruction infringes the Data Protection Legislation.</p>

<p>8.4 the Supplier consents to the Customer appointing an appropriate Insurer as a third-party processor of Personal Data under this agreement. The Customer confirms that it has entered or (as the case may be) will enter with the third-party processor into a written agreement substantially on that third party’s standard terms of business. As between the Supplier and the Customer, the Customer shall remain fully liable for all acts or omissions of any third-party processor appointed by it pursuant to this clause 9.5.</p>

<p>Either party may, at any time on not less than 30 days’ notice, revise this clause 10 by replacing it with any applicable controller to processor standard clauses or similar terms forming part of an applicable certification scheme (which shall apply when replaced by attachment to this agreement).</p>

<h4>9. Confidentiality</h4>
<p>9.1 All information of a confidential nature exchanged in connection with this Agreement shall be subject to the confidentiality provisions set forth in this clause 11.</p>

<p>9.2 <strong>“Confidential Information”</strong> means all confidential information (however recorded or preserved) disclosed by a party or its employees, officers, representatives or advisers (together its Representatives) to the other party and that party’s Representatives whether before or after the date of this agreement in connection with the Services, including information which:</p>
<p>9.2.1 relates to the existence and terms of this agreement;</p>
<p>9.2.2 would be regarded as confidential by a reasonable business person, relating to:</p>
<p>  (i) the business, assets, affairs, customers, clients, suppliers, or plans of the disclosing party (or of any member of the Group of companies to which the disclosing party belongs); and</p>
<p>  (ii) the operations, processes, product information, know-how, designs, trade secrets or software of the disclosing party (or of any member of the Group of companies to which the disclosing party belongs);</p>
<p>9.2.3 is developed by the parties in the course of carrying out this agreement and any Services; and</p>
<p>9.2.4 is specified as confidential in the Order Form.</p>

<p>9.3 The provisions of this clause shall not apply to any Confidential Information that:</p>
<p>9.3.1 is or becomes generally available to the public (other than as a result of its disclosure by the receiving party or its Representatives in breach of this clause); or</p>
<p>9.3.2 was available to the receiving party on a non-confidential basis before disclosure by the disclosing party; or</p>
<p>9.3.3 was, is or becomes available to the receiving party on a non-confidential basis from a person who, to the receiving party’s knowledge, is not bound by a confidentiality agreement with the disclosing party or otherwise prohibited from disclosing the information to the receiving party.</p>

<p>9.4 Each party shall keep the other party’s Confidential Information confidential and shall not:</p>
<p>9.4.1 use such Confidential Information except for the purpose of exercising or performing its rights and obligations under this agreement in relation to the Services (Permitted Purpose); or</p>
<p>9.4.2 disclose such Confidential Information in whole or in part to any third party, except as expressly permitted by this clause.</p>

<p>9.5 A party may disclose the other party’s Confidential Information to those of its Representatives who need to know such Confidential Information for the Permitted Purpose, provided that:</p>
<p>9.5.1 it informs such Representatives of the confidential nature of the Confidential Information before disclosure; and</p>
<p>9.5.2 it procures that its Representatives shall, in relation to any Confidential Information disclosed to them, comply with the obligations set out in this clause as if they were a party to this agreement, and at all times, it is liable for the failure of any Representatives to comply with the obligations set out in this clause.</p>

<p>9.6 A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this clause 11.5, it takes into account the reasonable requests of the other party in relation to the content of such disclosure.</p>

<p>9.7 A party may, provided that it has reasonable grounds to believe that the other party is involved in activity that may constitute a criminal offence under the Bribery Act 2010, disclose Confidential Information to the Serious Fraud Office without first informing the other party of such disclosure.</p>

<p>9.8 Each party reserves all rights in its Confidential Information. No rights or obligations in respect of a party’s Confidential Information other than those expressly stated in this agreement are granted to the other party, or to be implied from this agreement.</p>

<p>9.9 On termination of this agreement, unless such things are needed by it to perform its obligations under an Order Form(and only until the end of such time), each party shall:</p>
<p>9.9.1 return to the other party all documents and materials (and any copies) containing, reflecting, incorporating or based on the other party’s Confidential Information;</p>
<p>9.9.2 erase all the other party’s Confidential Information from computer and communications systems and devices used by it, including such systems and data storage services provided by third parties (to the extent technically practicable); and</p>
<p>9.9.3 certify in writing to the other party that it has complied with the requirements of this clause, provided that a recipient party may retain documents and materials containing, reflecting, incorporating or based on the other party’s Confidential Information to the extent required by law or any applicable governmental or regulatory authority. The provisions of this clause shall continue to apply to any such documents and materials retained by a recipient party.</p>

<p>9.10 The provisions of this Clause 10 shall survive for a period of five (5) years from termination of this agreement.</p>

<h4>10. Audit</h4>
<p>10.1 The Customer will allow the Supplier and any auditors of or other advisors of the Supplier to access any of the Customers records as may be required at any time. The Supplier shall use its reasonable endeavours to ensure that the conduct of each audit does not unreasonably disrupt the Customer. Subject to the Customer’s confidentiality obligations, the Customer shall provide the Supplier with all reasonable co-operation, access and assistance in relation to each audit.</p>

<h4>11. Force Majeure</h4>
<p>11.1 Neither Party will in any circumstances be liable to the other for any loss of any kind whatsoever including but not limited to any damages or abatement of Charges whether directly or indirectly caused to or incurred by the other Party by reason of any failure or delay in the performance of its obligations hereunder which is due to circumstances beyond the reasonable control of the affected Party including (without limitation) acts of God or fire flood strike or labour disputes civil commercial sabotage statute order or any regulation of any government public or local authority (“Force Majeure”).</p>

<p>11.2 If either of the Parties becomes aware of circumstances of Force Majeure it will forthwith notify the other Party of the period which it is estimated that the failure or delay will continue.</p>

<h4>12. Transfer and Sub-Contracting</h4>
<p>12.1 Supplier will not assign, novate, sub-contract or otherwise dispose of the Agreement in whole or in part without the prior written consent of Customer.</p>

<p>12.2 The obligations of Customer set forth in this Agreement may be performed by Customer, itself and through its Group companies and all references to Customer in this Agreement will include those Group companies. Customer may subcontract any portion of the Services to its Group Companies providing that Customer will remain liable for the performance of its Group Companies to the same extent as if Customer were performing itself.</p>

<h4>13. Warranties and Representations</h4>
<p>13.1 Supplier will discharge its obligations hereunder with all due skill and care and in accordance with its own established internal procedures.</p>
<p>13.2 Supplier has full capacity and authority to enter into and perform the Services in relation to this Agreement.</p>
<p>13.3 Except as expressly stated in this Agreement, all warranties and conditions, whether express or implied by statute, common-law or otherwise (including but not limited to satisfactory quality and fitness for purpose) are hereby excluded to the extent permitted by law.</p>

<h4>14. Limitation of Liability</h4>
<p>14.1 Neither Party shall exclude or limit its liability for fraud, fraudulent misrepresentation, death or personal injury arising from its negligence or that of its employees, agents or subcontractors, a breach of Data Protection Legislation or any breach of any obligations implied in the Sale of Goods Act 1893 as amended by the Sale of Goods and Supply of Services Act 1980 and the following clauses should be read as subject to this clause.</p>
<p>14.2 In no event will either Party be liable to the other Party for (a) indirect or consequential loss or damage.</p>

<h4>15. Intellectual Property Rights</h4>
<p>15.1 As between Sprive and Customer, Customer exclusively owns all rights, title and interest in and to all Customer Data. Subject to the limited rights expressly granted hereunder, Sprive reserves all rights, title and interest in and to the Sprive Services, and any related material provided by Sprive under this Agreement.  Sprive may use Customer’s name verbally and Customer’s name and logo in writing to identify Customer as a customer of Braze. No rights are granted to either party hereunder other than as expressly set forth herein.</p>
<p>15.2 Customer shall not (a) modify, copy or create derivative works based on the Sprive Services; (b) reverse engineer the Sprive Services; (c) alter, remove or suppress in any manner any copyright, trademark or other notices displayed by the Sprive Services or the Braze SDK; or (d) access or provide access to a User to the Sprive Services (i) for competitive purposes, or (ii) in order to copy any features, functions or graphics of the Sprive Services.</p>
<p>15.3 If Customer elects to provide any suggestions, comments, improvements, ideas or other feedback relating to the Sprive Services to Sprive (collectively, “Feedback”), Customer acknowledges and agrees that Sprive may incorporate into the Sprive Services any such Feedback without any obligation, payment, or restriction based on intellectual property rights or otherwise, excluding any Customer Confidential Information contained in the Feedback.</p>

<h4>16. Notices</h4>
<p>Any notice or other communication to be given under this Agreement must be in writing and may be delivered or sent by pre-paid first class letter post or facsimile transmission to the Party to be served at that Party’s last known address. In the case of Customer all notices and other communication must be marked to the attention of the “Corporate Director”. Any notice or document will be deemed served: if delivered, at the time of delivery; if posted, forty-eight (48) hours after posting; and if sent by facsimile transmission, at the time of transmission.</p>

<h4>17. Severability</h4>
<p>If any provision of this Agreement is held invalid, illegal or unenforceable for any reason by any court of competent jurisdiction, such provision will be severed and the remainder of the provisions hereof will continue in full force and effect as if this Agreement had been executed with the invalid, illegal or unenforceable provision eliminated. In the event of a holding of invalidity so fundamental as to prevent the accomplishment of the purpose of this Agreement, Supplier and Customer will immediately commence good faith negotiations to remedy such invalidity.</p>

<h4>18. Waiver</h4>
<p>No failure or omission of a Party to enforce or observe any provision of this Agreement, or the failure of a Party to exercise any right or remedy to which it is entitled hereunder, will constitute a waiver thereof and will not cause a diminution of the obligations established by this Agreement.</p>

<h4>19. Relationship</h4>
<p>Customer and Supplier acknowledge and agree that this Agreement does not constitute, create or give effect to a joint venture, pooling arrangement principal/agency relationship, partnership or formal business organisation of any kind and neither Supplier nor Customer will have the right to bind the other without the other’s express prior written consent.</p>

<h4>20. Third Party Rights</h4>
<p>A person who is not Party to this Agreement shall have no rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any terms of this Agreement. This Clause 21 does not affect any right or remedy of any person which exists or is available otherwise than pursuant to that Act.</p>

<h4>21. Publicity</h4>
<p>Each Party will coordinate with the other regarding any media release, public announcement or similar disclosure relating to this Agreement or its subject matter and will give the other Party a reasonable opportunity to review and comment on the content of such release, announcement or disclosure prior to its release; provide, however, that this provision will not alter the restrictions on the disclosure of confidential information set forth in this Agreement and, subject to such restrictions, will not be construed so as to delay or restrict either from disclosing any information required to be disclosed in order to comply with any applicable laws, rules or regulations.</p>

<h4>22. Entire Agreement</h4>
<p>22.1 This Agreement together with any documents referred to in this Agreement sets out the entire agreement and understanding between the Parties with respect to the subject matter hereof. It is agreed that:</p>
<p>22.1.1 neither Party has entered into this Agreement in reliance upon any representation, warranty or undertaking of the other Party which is not expressly set out or referred to in this Agreement and there are no collateral or supplemental agreements at the time this Agreement is entered into.</p>
<p>22.1.2 a Party may claim in contract for breach of warranty under this Agreement but otherwise shall have no claim or remedy in respect of misrepresentation (whether negligent or otherwise) or untrue statement made by the other Party.</p>
<p>22.1.3 this Agreement together with any documents referred to in this Agreement supersedes and replaces, without limitation, all earlier discussions, communications, understandings and arrangements of any kind between the Parties (howsoever made) relating to such subject matter.</p>
<p>22.2 this clause 22 shall not exclude for either Party any liability for fraudulent misrepresentation.</p>

<h4>23. Law and Jurisdiction</h4>
<p>23.1 This Agreement shall be considered as a contract made in England and according to English law.</p>
<p>In the event of any dispute arising between the Parties in connection with this Agreement which cannot be resolved by the Parties immediate contract representatives, senior representatives of the Parties will, within twenty-one (21) days of a written request from either Party to the other, meet in good faith to attempt to resolve the dispute without recourse to legal proceedings, failing which this agreement is subject to the exclusive jurisdiction of the Courts of England and to which both Parties hereby submit.</p>

<p>Appendix 1<br/>Processing, Personal Data and Data Subjects</p>

<p>1. PROCESSING BY THE CUSTOMER</p>

<p>1.1 SCOPE<br/>To provide mortgage quotes and policies to clients who have made an enquiry via the Supplier and their partners within a reasonable time of receiving the data</p>

<p>1.2 NATURE<br/>Receiving, uploading, updating potential client records</p>

<p>1.3 PURPOSE OF PROCESSING<br/>To contact the proposed client by phone, email or text in order to make a sale</p>

<p>1.4 DURATION OF THE PROCESSING<br/>Attempted contact will be made as soon as the data is received or as soon as possible afterwards but within customers working hours.</p>

<p>2. TYPES OF PERSONAL DATA<br/>Identity data<br/>Address data<br/>Contact data<br/>Lifestyle data<br/>Quote details<br/>Policy details</p>

<p>3. CATEGORIES OF DATA SUBJECT<br/>Clients or potential clients</p>

  </>
);

export default PrivacyPage;
